import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomepageComponent } from './homepage/homepage.component';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { VideoCallComponent } from './video-call/video-call.component';
import { FoyerComponent } from './foyer/foyer.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    HomepageComponent,
    VideoCallComponent,
    FoyerComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    MatToolbarModule,
    MatSnackBarModule,
    // DunsmuirAuthModule,
    // DunsmuirUiModule,
    HammerModule
  ],
  providers: [
    SwUpdate,
    MatSnackBar,
    {
      provide: 'environment',
      useValue: environment
    },
  ],
  bootstrap: [HomepageComponent]
})
export class AppModule { }
