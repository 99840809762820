import { Component, OnInit } from '@angular/core';
// import { BusyService, SnackbarService } from 'dunsmuir-ui';
// import { AuthenticationService } from 'dunsmuir-auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isAppBusy: boolean = false;

  constructor(
    // private authenticationService: AuthenticationService,
    // private snackbarService: SnackbarService,
    // private busyService: BusyService
  ) {}

  ngOnInit() {
    // this.busyService.busyState.subscribe((isBusy) => {
    //   this.isAppBusy = isBusy;
    // });
  }

  googleSignIn() {
    // if(!this.isAppBusy) {
    //   this.busyService.setBusy();
    //   this.authenticationService.signInWithGoogle().subscribe(
    //     (userProfile) => {},
    //     (err) => {
    //       this.busyService.setIdle();
    //     },
    //     () => {
    //       this.busyService.setIdle();
    //     }
    //   );
    // }
  }
}
