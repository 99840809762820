import { Component, OnInit, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import adapter from 'webrtc-adapter';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit, AfterContentInit {
  controlsActiveTimeoutHandle: NodeJS.Timeout;
  @ViewChild('localVideo', { static: true })
  localVideoContainer: ElementRef<HTMLVideoElement>;
  @ViewChild('controls', { static: true })
  controlsDiv: ElementRef<HTMLDivElement>;
  @ViewChild('deviceSwitcher', { static: true })
  switcherDiv: ElementRef<HTMLDivElement>;
  @ViewChild('muteButton', { static: true })
  muteSvg: ElementRef<SVGElement>;
  @ViewChild('pauseButton', { static: true })
  pauseSvg: ElementRef<SVGElement>;
  @ViewChild('hangupButton', { static: true })
  hangupSvg: ElementRef<SVGElement>;
  myStream: MediaStream;

  controlsActive = true;
  muteActive = false;
  pauseActive = false;

  devices: MediaDeviceInfo[] = [];
  filteredDevices: MediaDeviceInfo[] = [];

  shimAdapter;

  constructor() { }

  ngOnInit() {
    this.shimAdapter = adapter;
    window.navigator.getUserMedia({ video: true, audio: true}, (mediaStream: MediaStream) => {
      this.localVideoContainer.nativeElement.srcObject = mediaStream;
      setTimeout(() => { mediaStream.getTracks().forEach(track => { track.stop(); }); }, 5000);
      window.navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
        this.devices = devices;
      });
    }, function() {});
  }

  ngAfterContentInit() {
    this.extendControls();
  }

  onSwitchInput($event, deviceType) {
    this.filteredDevices = this.devices.filter((device) => device.kind === deviceType);
    const top = $event.center.y, left = $event.center.x + 24;
    this.switcherDiv.nativeElement.style.left = `${left}px`;
    this.switcherDiv.nativeElement.style.top = `${top}px`;
    this.switcherDiv.nativeElement.setAttribute('size', this.filteredDevices.length.toString());
    this.switcherDiv.nativeElement.classList.add('open');
  }

  onDeviceSwitch($event) {
    //const constraints = { audio: {deviceId: audioSource ? {exact: audioSource} : undefined}, video: {deviceId: videoSource ? {exact: videoSource} : undefined}}; navigator.mediaDevices.getUserMedia(constraints)
    this.switcherDiv.nativeElement.classList.remove('open');
  }
  
  onControlsTraySwipe($event) {
    if ($event.deltaX > 0) {
      this.extendControls();
    } else {
      if(this.controlsActiveTimeoutHandle) {
        clearTimeout(this.controlsActiveTimeoutHandle);
      }
      this.controlsActive = false;
    }
  }

  onControlsTrayTap($event) {
    if (this.controlsActive) {
      if(this.controlsActiveTimeoutHandle) {
        clearTimeout(this.controlsActiveTimeoutHandle);
      }
      this.controlsActive = false;
    } else {
      this.extendControls();
    }
  }

  onControlsTap($event) {
    if ($event.target === this.controlsDiv.nativeElement) {
      if (this.controlsActive) {
        if(this.controlsActiveTimeoutHandle) {
          clearTimeout(this.controlsActiveTimeoutHandle);
        }
        this.controlsActive = false;
      } else {
        this.extendControls();
      }
    } else {
      this.extendControls();
      if (this.muteSvg.nativeElement.contains($event.target)) {
        this.muteActive = !this.muteActive;
      } else if (this.pauseSvg.nativeElement.contains($event.target)) {
        this.pauseActive = !this.pauseActive;
      } else if (this.hangupSvg.nativeElement.contains($event.target)) {
        // hang up!
      }
    }
  }

  extendControls() {
    if(this.controlsActiveTimeoutHandle) {
      clearTimeout(this.controlsActiveTimeoutHandle);
    }
    this.controlsActive = true;
    this.controlsActiveTimeoutHandle = setTimeout(() => { this.controlsActive = false; }, 2000);
  }
}
