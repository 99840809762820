import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Observable, merge, of, fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewChecked, OnDestroy {
  subscriptions: Subscription[] = [];
  onlineOffline: boolean = navigator.onLine;
  online$: Observable<boolean>;
  lastOnline = true;
  fragment: string;
  tenthsUnlocked = 0;
  unlockSpecialFeatures = false;
  currentYear = (new Date()).getFullYear();

  constructor(
    updates: SwUpdate,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
    ) {
    window.addEventListener('online', () => {this.onlineOffline = true; });
    window.addEventListener('offline', () => {this.onlineOffline = false; });

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );

    this.subscriptions.push(this.online$.subscribe(status => {
      if(!status && this.lastOnline) {
        this.lastOnline = false;
        snackBar.open('Your browser is no longer connected to the internet ...', undefined, {
          duration: 2000,
        });
      } else if (status && !this.lastOnline) {
        this.lastOnline = true;
        snackBar.open('... aaaand we\'re back!', undefined, {
          duration: 2000,
        });
        updates.checkForUpdate().then(() => {});
      }
    }));

    this.subscriptions.push(updates.available.subscribe(event => {
      snackBar.open('A new version of this site has been retrieved and will be displayed the next time you load this site ...');
    }));
    this.subscriptions.push(updates.activated.subscribe(event => {
      snackBar.open('The latest version of this site is now being displayed. Enjoy!');
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => { if (!sub.closed) { sub.unsubscribe(); } });
  }

  ngAfterViewChecked(): void {
    try {
        if (this.fragment) {
            document.querySelector('#' + this.fragment).scrollIntoView();
        }
    } catch (e) { }
  }

  isActive(target: string) {
    return this.fragment === target;
  }

  onPan($event) {
    if ($event.deltaX > 0) {
      if ((($event.center.x - $event.deltaX) / ($event.target.parentNode.clientWidth * 1.0)) < 0.1) {
        this.tenthsUnlocked = Math.ceil($event.deltaX / ($event.target.parentNode.clientWidth * 0.10));
        if (this.tenthsUnlocked > 9) {
          this.tenthsUnlocked = 10;
        }
      } else {
        this.tenthsUnlocked = 0;
      }
    } else {
      this.tenthsUnlocked = 0;
    }
    if ($event.isFinal) {
      if (this.tenthsUnlocked === 10) {
        this.snackBar.open('You have unlocked special features!', undefined, {
          duration: 2000,
        });
        setTimeout(() => {
          this.unlockSpecialFeatures = true;
        }, 1000);
      } else {
        this.tenthsUnlocked = 0;
      }
    }
  }

  lockSpecialFeatures() {
    this.unlockSpecialFeatures = false;
    this.tenthsUnlocked = 0;
  }
}
