import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-foyer',
  templateUrl: './foyer.component.html',
  styleUrls: ['./foyer.component.scss']
})
export class FoyerComponent implements OnInit {
  @Output()
  exitFoyer: EventEmitter<void> = new EventEmitter<void>();

  // userProfile: UserProfile;
  isSignedIn = false;
  isAppBusy = false;

  constructor(
    // private authenticationService: AuthenticationService,
    // private busyService: BusyService
  ) { }

  ngOnInit() {
    // this.authenticationService.currentUserProfile.subscribe((userProfile) => {
    //   this.userProfile = userProfile;
    //   this.isSignedIn = (userProfile && userProfile.isSignedIn) && (userProfile.awsId !== undefined);
    // });
    // this.busyService.busyState.subscribe((isBusy) => {
    //   this.isAppBusy = isBusy;
    // });
  }

  clickClose() {
    this.exitFoyer.emit();
  }
}
